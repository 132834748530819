<template>
  <div>
    <vue-cropper
      ref="cropper"
      :aspect-ratio="1"
      :background="false"
      :guides="true"
      :src="chosenImage"
      class="cropper"
      drag-mode="move"
    />

    <div class="d-flex float-right mt-2">
      <b-button class="mr-1" variant="danger" @click="$emit('close')">Cancel</b-button>
      <b-button :disabled="loading" variant="success" @click="cropImage">Save</b-button>
    </div>
  </div>
</template>

<script>
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs/VueCropper";

export default {
  name: "ThumbnailCropperModal",
  components: { VueCropper },
  props: {
    chosenImage: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cropper: null,
      loading: false,
    };
  },
  mounted() {
    this.cropper = this.$refs.cropper;
  },
  methods: {
    async cropImage() {
      this.loading = true;

      const cropperInstance = this.$refs.cropper.cropper;
      if (!cropperInstance) {
        this.loading = false;
        return;
      }
      const previewUrl = cropperInstance
        .getCroppedCanvas()
        .toDataURL(this.$props.fileType);
      const blob = await new Promise((resolve, _) => {
        cropperInstance.getCroppedCanvas().toBlob(blob => {
          resolve(blob);
        }, this.$props.fileType);
      });
      this.loading = false;
      this.$emit("crop", previewUrl, blob);
    },
  },
};
</script>

<style lang="scss" scoped>
.cropper {
  max-height: 400px;
}
</style>
